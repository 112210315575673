import React, { ReactNode, useState } from 'react';
import Image from 'next/image';
import Button from 'components/LSRevamp/common/Button';
import { AccordionData } from 'components/LSRevamp/LSRevampInterfaces';
import { imageBasePath } from 'utils/imageUtils';
import useLogin, { FlowType } from 'hooks/useLogin';
import useDevice from 'hooks/useDevice';

const Divider: React.FC = () => {
  return <div className="h-[1px] bg-[#CDCDCD]"></div>;
};

interface AccordianProps {
  title: string;
  content: string;
  position: number;
  activeAccordionPosition: number | null;
  setActiveAccordionPosition: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}

const Accordion: React.FC<AccordianProps> = ({
  title,
  content,
  position,
  activeAccordionPosition,
  setActiveAccordionPosition,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleActive = () => {
    setIsActive(!isActive);
    setActiveAccordionPosition(position);
  };
  const show = isActive && activeAccordionPosition === position;

  return (
    <>
      <div
        className="flex justify-between items-center py-4 gap-3 md:cursor-pointer"
        onClick={handleActive}
      >
        <h3 className="font-bold text-lg">{title}</h3>
        <div className="w-4 shrink-0">
          {show ? (
            <Image
              height={16}
              width={16}
              src={`${imageBasePath}/avataran_assets/icons/LSRevamp/minus.svg`}
              alt="minus-icon"
            />
          ) : (
            <Image
              height={16}
              width={16}
              src={`${imageBasePath}/avataran_assets/icons/LSRevamp/plus.svg`}
              alt="plus-icon"
            />
          )}
        </div>
      </div>
      {show ? (
        <div
          className="text-base text-[#424242] mb-4"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {content}
        </div>
      ) : null}
    </>
  );
};

interface Props {
  data: AccordionData[];
  buttonText?: string | ReactNode;
  hideButton?: boolean;
  trackingEvent?: () => void;
  faqCtaHandler?: () => void;
  bgColor?: string;
  faqHeading?: string | null;
  isCtaFixed?: boolean;
}

const FAQAccordion: React.FC<Props> = (props) => {
  const { isMobile } = useDevice();
  const {
    data,
    buttonText,
    trackingEvent,
    faqCtaHandler,
    hideButton = false,
    bgColor,
    faqHeading,
    isCtaFixed,
  } = props;

  const [activeAccordionPosition, setActiveAccordionPosition] = useState<
    number | null
  >(null);

  const { handleSpotCounsellingFlow } = useLogin(FlowType.IELTS);

  return (
    <div
      className={
        bgColor
          ? bgColor + ' px-5 py-8 md:px-0 md:py-[80px]'
          : 'bg-[#F3F4FF] px-5 py-8 md:px-0 md:py-[80px]'
      }
    >
      <div className="flex flex-col items-center md:flex-row-reverse md:max-w-[1120px] md:m-auto md:justify-between md:items-start">
        <div className="ml-4 md:ml-60 md:-m-4 md:mb-7 mt-[-55px] md:mt-[20px]">
          <Image
            src={`${imageBasePath}/avataran_assets/images/LSRevamp/accordion-img.png`}
            alt="accordion-img"
            width={isMobile ? 342 : 456}
            height={isMobile ? 330 : 447}
            style={{
              objectFit: 'contain',
            }}
          />
        </div>

        <div className="mt-16 md:mt-0">
          <h2 className="font-bold text-2xl md:text-4xl bg text-center md:text-left mb-4 md:mb-[36px]">
            {faqHeading || 'Got questions? Find answers here.'}
          </h2>
          <div className="md:w-[550px]">
            {data?.map(({ title, content }, i) => {
              return i !== data.length - 1 ? (
                <div key={i}>
                  <Accordion
                    title={title}
                    content={content}
                    activeAccordionPosition={activeAccordionPosition}
                    position={i}
                    setActiveAccordionPosition={setActiveAccordionPosition}
                  />
                  <Divider />
                </div>
              ) : (
                <div key={i}>
                  <Accordion
                    title={title}
                    content={content}
                    activeAccordionPosition={activeAccordionPosition}
                    position={i}
                    setActiveAccordionPosition={setActiveAccordionPosition}
                  />
                </div>
              );
            })}
          </div>
          {!hideButton && (
            <div
              className={`flex justify-center pt-3 md:justify-start md:mt-[44px] ${
                isCtaFixed
                  ? 'fixed md:relative bottom-0 left-0 w-[100vw] md:w-auto px-2 py-4 md: bg-white md:px-0 md:py-0 md:bg-transparent'
                  : ''
              }`}
            >
              {typeof buttonText === 'string' ||
              typeof buttonText === 'undefined' ? (
                <Button
                  text={buttonText || 'Talk to an Expert'}
                  color="text-white"
                  backgroundColor="bg-primary md:bg-yellow-to-orange"
                  width={`${isCtaFixed ? 'w-full' : 'w-[280px]'}  md:w-[308px]`}
                  trackingEvent={trackingEvent}
                  handleSpotCounsellingFlow={() => {
                    if (faqCtaHandler) {
                      faqCtaHandler();
                    } else {
                      if (handleSpotCounsellingFlow) {
                        handleSpotCounsellingFlow();
                      }
                    }
                  }}
                />
              ) : (
                buttonText
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQAccordion;
