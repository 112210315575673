import BackGroundImage from 'components/DemoLandingPage/BackGroundImage';
import StudentCardV2 from 'components/DemoLandingPage/StudentCardV2';
import { StudentTestimonialsData } from 'components/LSRevamp/countryPage/types';
import { ReviewDataSchema } from 'Interfaces/reducerTypes/ReviewSchema';
import React, { ReactNode } from 'react';
import { imageBasePath } from 'utils/imageUtils';

type Props = {
  title: string | ReactNode;
  testimonialDataProps: Array<StudentTestimonialsData>;
};
const TestimonialsV2: React.FC<Props> = ({ testimonialDataProps, title }) => {
  return (
    <BackGroundImage
      backGroundImageUrl={`${imageBasePath}/avataran_assets/images/demopage/testimonialbg.svg`}
      mobileBackgroundUrl={`${imageBasePath}/avataran_assets/images/demopage/testimonialbgmweb.svg`}
    >
      <div className="md:pl-24 pt-16 pb-[100px]">
        <h3 className="leading-8 text-2xl text-center font-bold md:text-[42px] md:leading-[56px] mb-16">
          {title}
        </h3>
        <div className="flex gap-x-6  md:overflow-x-hidden md:hover:overflow-x-scroll overflow-x-scroll  pb-3 scroll-smooth">
          {testimonialDataProps.map((item) => (
            <StudentCardV2 key={item.image} {...item} />
          ))}
        </div>
      </div>
    </BackGroundImage>
  );
};
export default TestimonialsV2;
