import React from 'react';
import Image from 'next/image';
import { imageBasePath } from 'utils/imageUtils';

interface Props {
  rating: number;
  ratingText?: string;
  ratingPlatformIcon?: string;
}

const filledStar = `${imageBasePath}/avataran_assets/icons/LSRevamp/rating-stars/yellow-star.svg`;
const halfFilledStar = `${imageBasePath}/avataran_assets/icons/LSRevamp/rating-stars/half-filled-star.svg`;
const unfilledStar = `${imageBasePath}/avataran_assets/icons/LSRevamp/rating-stars/gray-star.svg`;

const RatingStars: React.FC<Props> = ({
  rating,
  ratingPlatformIcon,
  ratingText,
}) => {
  const filledCount = Math.floor(rating);
  const unfilledCount = 5 - Math.ceil(rating);
  const halfFilledCount = filledCount + unfilledCount !== 5 ? 1 : 0;

  return (
    <div className="flex gap-1 items-center">
      {ratingPlatformIcon ? (
        <Image
          src={ratingPlatformIcon}
          width={20}
          height={20}
          alt="platform-icon"
        />
      ) : null}
      {new Array(filledCount).fill(filledStar).map((el: string, i: number) => (
        <Image alt="filled-star" src={el} key={i} width={14} height={14} />
      ))}
      {new Array(halfFilledCount)
        .fill(halfFilledStar)
        .map((el: string, i: number) => (
          <Image alt="half-star" src={el} key={i} width={14} height={14} />
        ))}
      {new Array(unfilledCount)
        .fill(unfilledStar)
        .map((el: string, i: number) => (
          <Image alt="unfilled-star" src={el} key={i} width={14} height={14} />
        ))}
      {ratingText ? (
        <p className="text-[12px] text-[#8E8E8E] ml-1">{ratingText}</p>
      ) : null}
    </div>
  );
};

export default RatingStars;
