import Link from "next/link";
import React from "react";

interface ButtonProps {
  color: string;
  path?: string;
  text: string;
  backgroundColor: string;
  border?: string;
  width?: string;
  icon?: React.ReactNode;
  iconPosition?: string;
  link?: string;
  padding?: string;
  handleSpotCounsellingFlow?: () => void;
  trackingEvent?: () => void;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    color,
    path,
    text,
    backgroundColor,
    border,
    width,
    icon,
    iconPosition,
    link,
    padding,
    handleSpotCounsellingFlow,
    trackingEvent,
  } = props;

  const flexClasses =
    iconPosition === "right" ? "flex-row-reverse" : "flex-row";

  const commonClass = `${color} ${backgroundColor} ${
    border || "border border-transparent"
  } ${width} rounded ${padding ?? "py-3 px-2"} text-center text-sm ${
    icon
      ? `${flexClasses} justify-center gap-2 flex items-center`
      : "inline-block"
  }`;

  if (!(link || path))
    return (
      <button
        onClick={() => {
          if (trackingEvent) trackingEvent();
          if (handleSpotCounsellingFlow) handleSpotCounsellingFlow();
        }}
        className={commonClass}
      >
        {icon} <p className="font-bold leading-6">{text}</p>
      </button>
    );

  return path ? (
    <Link href={path}>
      <a
        className={commonClass}
        onClick={() => {
          if (trackingEvent) trackingEvent();
        }}
      >
        {icon} <p>{text}</p>
      </a>
    </Link>
  ) : (
    <a
      onClick={() => {
        if (trackingEvent) trackingEvent();
      }}
      href={link}
      className={commonClass}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon} <p className="font-bold leading-6">{text}</p>
    </a>
  );
};

export default Button;
