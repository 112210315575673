import RatingStars from 'components/LSRevamp/countryPage/RatingStars';
import { StudentTestimonialsData } from 'components/LSRevamp/countryPage/types';
import Image from 'next/image';
import { imageBasePath } from 'utils/imageUtils';

const StudentCardV2: React.FC<StudentTestimonialsData> = ({
  name,
  reviews,
  rating,
  image,
  score,
  ratingText,
}) => {
  return (
    <div className="flex shrink-0 gap-2 flex-col shadow-19 rounded-[20px] pt-5 pb-8 px-4 md:pb-16   w-[262px] md:w-[294px] bg-white">
      <div className="flex gap-x-3 items-center">
        {' '}
        <div>
          <Image src={image} width={40} height={40} alt={`${name} photo`} />
        </div>
        <div className="flex flex-col">
          <p className="text-[18px] font-bold text-[#1B1D6A]">{name}</p>
          <p className="flex gap-x-2 items-center">
            {' '}
            <span className="text-[#424242] text-[14px] !font-[Inter]">
              Band Score:{' '}
            </span>
            <span className="text-[20px] px-2 font-bold text-[#6da559] bg-[#E6FFE2]">
              {score}
            </span>
          </p>
        </div>
      </div>
      {rating ? (
        <RatingStars
          rating={+rating}
          ratingPlatformIcon={`${imageBasePath}/avataran_assets/icons/google.svg`}
          ratingText={ratingText}
        />
      ) : null}

      <p className="text-[#424242] md:text-sm md:leading-5 !font-[Inter] font-light mt-6">
        "{reviews}""
      </p>
    </div>
  );
};
export default StudentCardV2;
