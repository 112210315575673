import useDevice from 'hooks/useDevice';
import Image from 'next/image';
import { FunctionComponent, ReactNode } from 'react';

type Props = {
  backGroundImageUrl: string;
  className?: string;
  onClick?: () => void;
  mobileBackgroundUrl?: string | null;
  children: ReactNode;
  alt?: string;
  imageProps?: any;
};

const BackGroundImage: FunctionComponent<Props> = ({
  backGroundImageUrl,
  mobileBackgroundUrl = null,
  children,
  className = '',
  onClick = () => {},
  alt = '',
  imageProps = {},
}) => {
  const { isMobile } = useDevice();
  const imageUrl = isMobile
    ? mobileBackgroundUrl
      ? mobileBackgroundUrl
      : backGroundImageUrl
    : backGroundImageUrl;
  return (
    <div className={`relative`} onClick={onClick}>
      <Image
        src={imageUrl}
        alt={alt}
        fill
        style={{
          objectFit: 'cover',
          objectPosition: 'bottom',
        }}
        {...imageProps}
      />
      <div className={`relative ${className}`}>{children}</div>
    </div>
  );
};
export default BackGroundImage;
