import useDevice from 'hooks/useDevice';
import Image from 'next/image';
import { navRoutes } from 'page_routes';
import React from 'react';
import { trackIeltsClick } from 'scripts/segment';
import { ieltsPageName } from 'utils/events-utils';
import { imageBasePath } from 'utils/imageUtils';
interface Props {
  pageName?: string;
}
const AppBanner: React.FC<Props> = ({ pageName }) => {
  const { isMobile } = useDevice();
  const logSegmentEvent = () => {
    let eventProps = {
      widgetName: 'Get the App',
      widgetFormat: 'Banner',
      contentName: 'Get it on Google Play',
      contentFormat: 'Button',
    };

    trackIeltsClick(
      pageName || ieltsPageName.IELTS_CRASH_COURSE_PAGE,
      eventProps,
    );
  };
  return (
    <div className="py-[22px] md:py-[4.5rem] flex items-center justify-center relative md:static">
      <Image
        src={
          isMobile
            ? `${imageBasePath}/avataran_assets/images/demopage/appbanner_mweb.webp`
            : `${imageBasePath}/avataran_assets/images/demopage/appbanner.webp`
        }
        alt="ielts android app banner"
        className="cursor-pointer"
        onClick={() => {
          logSegmentEvent();
          window.open(navRoutes.IELTS_APP);
        }}
        width={isMobile ? 328 : 1150}
        height={isMobile ? 342 : 345}
      />
    </div>
  );
};
export default AppBanner;
