import Image from 'next/image';
import { ExpertCardItemsType } from 'components/LSRevamp/LSRevampInterfaces';
import useDevice from 'hooks/useDevice';
import { imageBasePath } from 'utils/imageUtils';

const ExpertCard = ({
  image = '',
  linkedinAddress,
  personName,
  numberOfStudents,
  yearsOfExperience = 1,
  expertise = [],
  content = '',
  gradientColors = { from: 'from-[#FFF3E0]', to: 'to-[#C3C9FE]' },
  withExpertise,
  className = '',
  widthClasses = '',
  headline = '',
  ieltsCard = false,
}: ExpertCardItemsType) => {
  const { isMobile } = useDevice();
  return (
    <div
      className={`${
        widthClasses.length > 0 ? widthClasses : 'w-64 md:w-[313px] '
      } rounded-md  mt-10 mb-4 ${className}`}
      style={{
        boxShadow:
          '0px 4px 6px rgba(65, 52, 146, 0.06), 0px 4px 12px rgba(65, 52, 146, 0.12)',
      }}
    >
      <div
        className={`h-[190px] md:h-60 w-full bg-gradient-to-b ${gradientColors.from} ${gradientColors.to} rounded-t-6 relative flex justify-center`}
      >
        {linkedinAddress ? (
          <a
            href={linkedinAddress}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute top-[10px] right-[10px] z-10"
          >
            <Image
              src={`${imageBasePath}/avataran_assets/images/LSRevamp/linkedin.svg`}
              alt="linkedin"
              width={isMobile ? 18 : 22}
              height={isMobile ? 18 : 22}
            />
          </a>
        ) : null}

        <div className="bg-center overflow-hidden">
          {image?.length > 0 && (
            <Image
              src={image}
              alt="person"
              width={isMobile ? 220 : 261}
              height={isMobile ? 190 : 240}
              style={{
                objectFit: 'contain',
              }}
            />
          )}
        </div>
      </div>
      <div className="px-5 mt-3">
        <p className="font-semibold text-base md:text-lg mb-1">{personName}</p>
        {withExpertise ? (
          <p className="font-extralight text-xs md:text-sm text-[#6A6A6A]">
            <span className="font-semibold text-sm text-black">
              {numberOfStudents}+
            </span>{' '}
            students
            <span className="font-semibold text-sm text-black">
              {' '}
              | {yearsOfExperience}+
            </span>{' '}
            yrs exp
          </p>
        ) : (
          <p className="font-extralight text-xs md:text-sm text-[#2B313B]">
            {headline}
          </p>
        )}
        <p className="border-solid border-[0.5px] border-[#DBDBDB] mt-[10px]"></p>
        {withExpertise && expertise.length > 0 ? (
          <>
            <p className="font-semibold text-xs md:text-sm text-[#8E8E8E] mt-3">
              Expertise in
            </p>
            <div className="my-2 pb-4">
              {expertise?.map((item, index) => (
                <span
                  className="rounded-xl px-3 py-1 text-xs md:text-sm bg-[#E9EDF9] mr-2"
                  key={index}
                >
                  {item}
                </span>
              ))}
            </div>
          </>
        ) : (
          <div className="font-extralight text-xs md:text-sm text-[#2B313B] mt-2 mb-3 pb-4">
            {numberOfStudents && (
              <span className="font-bold">{numberOfStudents}+ </span>
            )}
            {content}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpertCard;
